(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory((function webpackLoadOptionalExternalModule() { try { return require("esprima"); } catch(e) {} }()));
	else if(typeof define === 'function' && define.amd)
		define(["esprima"], factory);
	else if(typeof exports === 'object')
		exports["SwaggerUIBundle"] = factory((function webpackLoadOptionalExternalModule() { try { return require("esprima"); } catch(e) {} }()));
	else
		root["SwaggerUIBundle"] = factory(root["esprima"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__891__) {
return 